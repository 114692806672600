import { useMutation } from '@tanstack/react-query';
import https from 'utils/https';
import { ENDPOINTS } from 'utils/endpoints/endpoints';

async function content(id) {
  const { data } = await https.get(`${ENDPOINTS.CONTENT}/${id}`);

  return data;
}

export const useContentMutation = () => {
  return useMutation({
    mutationFn: id => content(id),
    onSuccess: async data => {
      console.log(data);
    },
    onError: error => {
      console.log(error);
    }
  });
};
