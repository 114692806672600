import React from 'react';
import SignupForm from 'components/authentication/SignupForm';
import bg from 'assets/img/login_bg.jpg';

const Signup = () => {
  const title = 'Request Access';
  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
      className="login-page"
    >
      <div className="login-form-wrapper">
        <SignupForm />
      </div>
    </div>
  );
};

export default Signup;
