import { useEffect, useMemo, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const FurtitureFixtures = ({
  form,
  action,
  variant = 'normal',
  setPath,
  setForm
}) => {
  const [sub, setSub] = useState('');
  const appsetting = useSelector(state => state.appsetting);
  const { category_tree } = appsetting;
  const findFixturesFurnitures = category_tree.find(el =>
    el.name.toLowerCase().includes('fixtures')
  );

  const f_f_dropdown = findFixturesFurnitures?.sub_branches?.find(el =>
    el.name.toLowerCase().includes('shops')
  );
  const sub_dropdowns = f_f_dropdown?.sub_branches?.find(el =>
    variant === 'portfolio'
      ? el._id === sub
      : el._id === form.category01ISbb_q4sg_content_categoriesID
  );

  const pathNameCat = useMemo(
    () =>
      variant === 'portfolio'
        ? f_f_dropdown?.sub_branches.find(el => el._id === form.category)
        : f_f_dropdown?.sub_branches.find(
            el => el._id === form.category01ISbb_q4sg_content_categoriesID
          ),
    [form, f_f_dropdown]
  );

  const pathNameSub = useMemo(
    () =>
      sub_dropdowns?.sub_branches.find(
        el => el._id === form.category03ISbb_q4sg_content_categoriesID
      ),
    [form, sub_dropdowns]
  );

  useMemo(
    () => setPath(`/${pathNameCat?.name}/${pathNameSub?.name}`),
    [(pathNameCat?.name, pathNameSub?.name)]
  );

  useEffect(() => {
    if (variant === 'portfolio' && !pathNameSub?.name) {
      setForm({ ...form, category03ISbb_q4sg_content_categoriesID: '' });
    }
  }, [pathNameSub?.name]);

  return (
    <>
      <Row>
        <fieldset className={'form-legend-item-wrapper mb-4'}>
          <legend className="item-title fs-1 fw-semi-bold">
            F&F Category:
          </legend>
          <Form.Control
            as="select"
            required
            className="form-select"
            value={
              variant === 'portfolio'
                ? form.category
                : form.category01ISbb_q4sg_content_categoriesID
            }
            name={
              variant === 'portfolio'
                ? 'category'
                : 'category01ISbb_q4sg_content_categoriesID'
            }
            onChange={e => {
              action(e);
              setSub(e.target.value);
            }}
          >
            <option value="" disabled>
              Choose option
            </option>
            {f_f_dropdown.sub_branches.map((status, index) => (
              <option value={status._id} key={index} onChange={action}>
                {status.name}
              </option>
            ))}
          </Form.Control>
        </fieldset>
      </Row>
      {(variant === 'portfolio'
        ? form?.category01ISbb_q4sg_content_categoriesID !== ''
        : form?.category01ISbb_q4sg_content_categoriesID !== '') && (
        <Row>
          <fieldset className={'form-legend-item-wrapper mb-4'}>
            <legend className="item-title fs-1 fw-semi-bold">
              {sub_dropdowns?.name} Category:
            </legend>
            <Form.Control
              as="select"
              required
              disabled={
                variant === 'portfolio'
                  ? form.category === ''
                  : form?.category01ISbb_q4sg_content_categoriesID === ''
              }
              className="form-select"
              value={form.category03ISbb_q4sg_content_categoriesID}
              name={'category03ISbb_q4sg_content_categoriesID'}
              onChange={action}
            >
              <option value="" disabled>
                Choose option
              </option>
              {sub_dropdowns?.sub_branches?.map((status, index) => (
                <option value={status._id} key={index}>
                  {status.name}
                </option>
              ))}
            </Form.Control>
          </fieldset>
        </Row>
      )}
    </>
  );
};

export default FurtitureFixtures;
