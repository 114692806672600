import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CardDropdown from 'components/common/CardDropdown';
import Loading from 'components/loading';
import { getIcon } from 'helpers/utils';
import React, { useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import EditUserModal from '../modals/EditModal';
import DeleteUserModal from '../modals/DeleteModal';

const ActiveUsers = ({ users, updateSuccess }) => {
  const [selectedUser, setSelectedUser] = useState({});
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const handleDeleteUserModalClose = () => setShowDeleteUserModal(false);
  const handleEditUserModalClose = () => setShowEditUserModal(false);

  const { t } = useTranslation();
  const appsetting = useSelector(state => state.appsetting);
  const { user_status } = appsetting.dropdowns;
  const regions = appsetting.regions || [];
  const statuses = [...user_status];

  const userColumns = useMemo(() => {
    let result = [];
    result = [
      {
        accessor: 'name',
        Header: t('Name')
      },
      {
        accessor: 'email',
        Header: t('Email')
      },
      {
        accessor: 'region',
        Header: t('Region')
      },
      {
        accessor: 'status',
        Header: t('Status')
      },
      {
        accessor: 'user_type',
        Header: t('Type')
      },
      {
        accessor: 'action',
        Header: t('Action'),
        Cell: rowData => {
          const data = rowData.row.original;
          return (
            <div className="text-center">
              <ActionButtons data={data} />
            </div>
          );
        }
      }
    ];
    return result;
  }, [users]);

  const userData = useMemo(() => {
    const tempContacts = users;
    let results = [];
    results = tempContacts.map(c => {
      const status = statuses.find(s => s._id == c.on_holdYN)
        ? statuses.find(s => s._id == c.on_holdYN).name
        : 'Active';
      const region = regions.find(r => r._id == c.regionISbb_q4sg_regionsID)
        ? regions.find(s => s._id == c.regionISbb_q4sg_regionsID).name
        : '';
      return {
        _id: c._id,
        name: `${c.first_name} ${c.last_name}`,
        email: `${c.email}`,
        region,
        status,
        user_type: `${c.user_type}`
      };
    });
    return results;
  }, [users]);

  const onSelectUser = _id => {
    if (_id === null) {
      setSelectedUser({});
      return;
    }
    const user = users.find(u => u._id === _id);
    if (user) {
      setSelectedUser(user);
    }
  };

  const onEdit = (e, _id) => {
    onSelectUser(_id);
    setShowEditUserModal(true);
  };

  const onDelete = _id => {
    onSelectUser(_id);
    setShowDeleteUserModal(true);
  };

  const ActionButtons = ({ data }) => {
    const { _id } = data;
    return (
      <CardDropdown iconClassName="fs--1">
        <div className="py-2">
          <Dropdown.Item onClick={e => onEdit(e, _id)}>
            <span>
              {getIcon({
                icon_component: 'ri/RiEditBoxFill',
                icon_component_attributes: { fontSize: 16, marginRight: 10 }
              })}
            </span>
            <span>{t('Edit')}</span>
          </Dropdown.Item>
          <Dropdown.Item onClick={() => onDelete(_id)}>
            <span>
              {getIcon({
                icon_component: 'md/MdDelete',
                icon_component_attributes: { fontSize: 16, marginRight: 10 }
              })}
            </span>
            <span>{t('Delete')}</span>
          </Dropdown.Item>
        </div>
      </CardDropdown>
    );
  };

  return (
    <div className="extended-table">
      {users?.length === 0 ? (
        <Loading style={{ marginTop: 0, height: '100%' }} className="d-flex" />
      ) : (
        <AdvanceTableWrapper
          columns={userColumns}
          data={userData}
          sortable
          pagination
          perPage={10}
        >
          <AdvanceTable
            table
            headerClassName="bg-200 text-700 text-nowrap align-middle fs-0"
            rowClassName="align-middle white-space-nowrap fs-0"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0'
            }}
          />
          <div className="mt-3">
            <AdvanceTablePagination table />
          </div>
        </AdvanceTableWrapper>
      )}
      {showEditUserModal && (
        <EditUserModal
          show={showEditUserModal}
          isAdd={selectedUser._id ? false : true}
          user={selectedUser}
          handleClose={handleEditUserModalClose}
          success={updateSuccess}
        />
      )}
      {showDeleteUserModal && (
        <DeleteUserModal
          show={showDeleteUserModal}
          _id={selectedUser._id}
          handleClose={handleDeleteUserModalClose}
          success={updateSuccess}
        />
      )}
    </div>
  );
};

export default ActiveUsers;
