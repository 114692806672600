import { Button, Form, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useGetContentMutation } from 'utils/rest/content/getContent';
import ToastifyNotification from 'utils/toast/ToastifyNotification';
import DropFiles from 'components/pages/modal/content/DropFiles';
import { filesBase64 } from 'utils/files/filesToBase64';
import { useSelector } from 'react-redux';
import { useResubmitContentMutation } from 'utils/rest/content/resubmitContent';

const NewsFormCopy = ({ close, formData, setFormData, content, img }) => {
  const [images, setImages] = useState(img);
  const [dragging, setDragging] = useState(false);

  const { user } = useSelector(state => state.auth);
  const { mutate: getContent } = useGetContentMutation();
  const { mutate: resubmitContent } = useResubmitContentMutation();

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const dataImg = await filesBase64(formData, images, 'img');
    const updatedData = { ...dataImg, re_edit_itemYN: 1 };

    if (updatedData) {
      resubmitContent(
        { id: content?._id, body: updatedData },
        {
          onSuccess: () => {
            ToastifyNotification({
              message: 'Your content was successfully submitted',
              errorMessage: false
            });
            getContent(user?._id);
            close();
          },
          onError: () => {
            getContent(user?._id);
            close();
          }
        }
      );
    }
  };

  const handleDrop = (e, type) => {
    e.preventDefault();
    setDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);

    if (type === 'img') {
      setImages(prev => [...prev, ...droppedFiles]);
    } else {
      setDocuments(prev => [...prev, ...droppedFiles]);
    }
  };

  const handleDragOver = (e, type) => {
    e.preventDefault();
    if (type === 'img') {
      setDragging({ images: true, documents: false });
    } else {
      setDragging({ images: false, documents: true });
    }
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  useEffect(() => {
    setTimeout(() => {
      const acceptedImgsFormat = ['image'];

      const checkImagesFile = images?.filter(
        el =>
          acceptedImgsFormat.includes(el?.type?.split('/')[0]) ||
          acceptedImgsFormat.includes(el?.type?.split('/')[1])
      );

      if (images?.length !== checkImagesFile?.length) {
        ToastifyNotification({
          message: 'You can drop only "images" formats for Image',
          errorMessage: true
        });
        setImages(checkImagesFile);
      } else if (images && images?.length != 0 && images?.length > 1) {
        ToastifyNotification({
          message: 'You can drop only 1 image same time',
          errorMessage: true
        });
        const newImages = images.slice(0, 1);
        setImages(newImages);
      } else {
        setImages(images);
      }
    }, 500);
  }, [images]);

  return (
    <Form onSubmit={handleSubmit} className="px-6 pt-4">
      <Row>
        <fieldset className={'form-legend-item-wrapper mb-4'}>
          <legend className="item-title fs-1 fw-semi-bold">Name</legend>
          <Form.Control
            value={formData?.name}
            name="name"
            onChange={handleChange}
            required
          />
        </fieldset>
      </Row>
      <Row>
        <fieldset className={'form-legend-item-wrapper mb-4'}>
          <legend className="item-title fs-1 fw-semi-bold">Information</legend>
          <Form.Control
            value={formData?.summaryISsmallplaintextbox}
            name="summaryISsmallplaintextbox"
            onChange={handleChange}
            required
          />
        </fieldset>
      </Row>
      <Row>
        <fieldset
          className="form-legend-item-wrapper mb-4 position-relative"
          style={{ zIndex: 50 }}
        >
          <legend className="item-title fs-1 d-flex align-items-center">
            <span className="cursor-pointer fw-semi-bold">Image</span>
          </legend>
          <label
            style={{
              minHeight: '120px',
              maxHeight: '120px',
              width: '100%'
            }}
            onDragOver={e => handleDragOver(e, 'img')}
            onDragLeave={() => handleDragLeave('img')}
            onDrop={e => handleDrop(e, 'img')}
          >
            <DropFiles
              dragging={dragging}
              items={images}
              setFiles={setImages}
              form={formData}
              setForm={setFormData}
              type={'news'}
            />
          </label>
        </fieldset>
      </Row>

      <Button variant="primary" type="submit">
        Submit For Approval
      </Button>
    </Form>
  );
};

export default NewsFormCopy;
