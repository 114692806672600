import { Col, Form, Row } from 'react-bootstrap';

const MainCategory = ({ category, action }) => {
  const mainCategories = [
    { id: '9218', name: 'Fixtures and Furniture', slug: 'f&f' },
    { id: '22278', name: 'News', slug: 'news' },
    { id: '1111', name: 'Portfolio', slug: 'portfolio' }
  ];
  return (
    <Row>
      <fieldset className={'form-legend-item-wrapper mb-4'}>
        <legend className="item-title fs-1 fw-semi-bold">Choose option</legend>
        <Form.Control
          as="select"
          required
          className="form-select"
          value={category}
          name="category"
          onChange={action}
        >
          <option value="" disabled>
            Choose category
          </option>
          {mainCategories.map((status, index) => (
            <option value={status.slug} key={index} onChange={action}>
              {status.name}
            </option>
          ))}
        </Form.Control>
      </fieldset>
    </Row>
  );
};

export default MainCategory;
