import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Row, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import endpoint from 'services/endpoint';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { getErrorMesssage } from 'services/axiosHelpers';
import { setError } from 'redux/slicers/appsettingSlice';
import PageTitle from 'components/common/PageTitle';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const SignupForm = () => {
  const navigate = useNavigate();
  const title = 'Request Toolkit Access';

  const appsetting = useSelector(state => state.appsetting);
  const regions = appsetting.regions || [];

  const [isWorking, setIsWorking] = useState(false);
  const [passwordError, setPasswordError] = useState('');

  const passwordValidation = password => {
    const minLength = /.{6,}/;
    const uppercase = /[A-Z]/;
    const lowercase = /[a-z]/;
    const number = /[0-9]/;
    const specialChar = /[!@#$%^&*(),.?":{}|<>]/;

    if (!minLength.test(password)) {
      return 'Password must be at least 6 characters long.';
    }
    if (!uppercase.test(password)) {
      return 'Password must contain at least one uppercase letter.';
    }
    if (!lowercase.test(password)) {
      return 'Password must contain at least one lowercase letter.';
    }
    if (!number.test(password)) {
      return 'Password must contain at least one number.';
    }
    if (!specialChar.test(password)) {
      return 'Password must contain at least one special character.';
    }

    return '';
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();
  // State
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    regionISbb_q4sg_regionsID: '',
    user_type: 'Team'
  });

  const isDisabled = () => {
    let result = true;
    if (
      formData.first_name &&
      formData.last_name &&
      formData.email &&
      formData.regionISbb_q4sg_regionsID &&
      formData.password
    ) {
      result = false;
    }
    return result;
  };

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();
    const errorMessage = passwordValidation(formData.password);

    if (errorMessage) {
      setPasswordError(errorMessage);
      return;
    }

    setPasswordError(null);
    setIsWorking(true);
    try {
      const ep = `${endpoint.signup}`;
      const singupResponse = await axios.post(ep, formData);

      if (singupResponse.data.error) {
        const message = getErrorMesssage(singupResponse.data.error);
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
      } else {
        dispatch(
          setError({
            isShow: true,
            title: 'Success',
            message:
              'Thanks for requesting an account. You will receive an email once your account has been approved'
          })
        );

        navigate('/login');
      }
      setIsWorking(false);
    } catch (err) {
      const message = getErrorMesssage(err);
      dispatch(
        setError({
          isShow: true,
          title: 'Error',
          message
        })
      );
      setIsWorking(false);
    }
  };

  const handleFieldChange = e => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleNavigate = () => {
    navigate('/login');
  };

  return (
    <div className="bg-white login-form pb-4">
      <PageTitle title={title} />

      <Form onSubmit={handleSubmit} className="px-3">
        <div className="pt-2" style={{ maxWidth: '22vw' }}>
          <p>
            Don’t have an account yet? Fill in your details below to have your
            account activated
          </p>
        </div>
        <fieldset className={'form-legend-item-wrapper mb-3'}>
          <legend className="item-title fs--1">{t('First Name')}</legend>
          <Form.Group className="mb-0">
            <Form.Control
              placeholder={'First Name'}
              value={formData.first_name}
              name="first_name"
              onChange={handleFieldChange}
              type="text"
            />
          </Form.Group>
        </fieldset>

        <fieldset className={'form-legend-item-wrapper mb-3'}>
          <legend className="item-title fs--1">{t('Last Name')}</legend>
          <Form.Group className="mb-0">
            <Form.Control
              placeholder={'Last Name'}
              value={formData.last_name}
              name="last_name"
              onChange={handleFieldChange}
              type="text"
            />
          </Form.Group>
        </fieldset>

        <fieldset className={'form-legend-item-wrapper mb-3'}>
          <legend className="item-title fs--1">{t('Email')}</legend>
          <Form.Group className="mb-0">
            <Form.Control
              placeholder={'Email address'}
              value={formData.email}
              name="email"
              onChange={handleFieldChange}
              type="email"
            />
          </Form.Group>
        </fieldset>

        <fieldset className={'form-legend-item-wrapper mb-3'}>
          <legend className="item-title fs--1">{t('Region')}</legend>
          <Form.Control
            as="select"
            value={formData.regionISbb_q4sg_regionsID}
            name="regionISbb_q4sg_regionsID"
            onChange={handleFieldChange}
            required
            className="form-select"
          >
            <option defaultValue={t('Select Region')}>
              {t('Select Region')}
            </option>
            {regions.map((region, index) => (
              <option value={region._id} key={index}>
                {region.name}
              </option>
            ))}
          </Form.Control>
        </fieldset>
        <fieldset
          className={'form-legend-item-wrapper mb-3'}
          style={{ color: passwordError ? 'red' : '#5e6e82' }}
        >
          <legend className="item-title fs--1">{t('Password')}</legend>
          <Form.Group className="mb-0">
            <Form.Control
              placeholder={'Password'}
              value={formData.password}
              name="password"
              onChange={handleFieldChange}
              type="password"
            />
          </Form.Group>
        </fieldset>
        {passwordError && (
          <p style={{ color: 'red', fontSize: 12 }}>{passwordError}</p>
        )}
        <div className="d-flex gap-2">
          <Button
            type="submit"
            color="primary"
            className="mt-3"
            disabled={isDisabled() || isWorking}
          >
            Request Account
          </Button>

          <Button
            type="button"
            className="mt-3"
            color="primary"
            onClick={handleNavigate}
          >
            Back to Login
          </Button>
        </div>
      </Form>
    </div>
  );
};

SignupForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

export default SignupForm;
