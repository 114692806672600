import React from 'react';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { getIcon, getFileExtension } from 'helpers/utils';
import { truncateFileName } from './helpers';
import useTabletScreenSize from 'hooks/useTabletScreenSize';
import useSmallTabletScreenSize from 'hooks/useSmallTabletScreenSize';
import { usePostTrackingMutation } from 'utils/rest/tracking/postTracking';

const AccordionTable = ({ item, parent, contentId, content }) => {
  const { mutate: postTracking } = usePostTrackingMutation();

  const isTablet = useTabletScreenSize();
  const isSmallTablet = useSmallTabletScreenSize();

  const onFile = (url, type, data) => {
    if (!url) {
      return;
    }

    if (url && parent === 'As Builts') {
      const body = {
        contentISbb_q4sg_contentID: contentId,
        name: data.name,
        urlISsmallplaintextbox: url
      };

      postTracking(body, {
        onSuccess: () => {
          content(contentId);
        }
      });
    }

    window.open(url, '_blank');
  };

  const setTableData = item => {
    const result =
      item?.length > 0
        ? item.map((doc, idx) => {
            if (doc.tag) {
              const url = doc.name.endsWith('.dwg') ? 'dwg' : 'pdf';

              return {
                _id: idx,
                name: doc.name,
                [url]: doc.urlISsmallplaintextbox
              };
            } else {
              const uri = doc?.document01ISfile?.uri;
              const extension = uri ? getFileExtension(uri) : '';
              return {
                _id: doc._id,
                name: doc.data
                  ? doc.name.split('/')[doc.name.split('/').length - 1]
                  : doc.name,
                pdf: !['dwg', 'dxf'].includes(extension) ? uri : null,
                dwg: ['dwg', 'dxf'].includes(extension) ? uri : null
              };
            }
          })
        : [];

    return result;
  };

  const columns = [
    {
      accessor: 'name',
      // Header: t(''),
      Header: '',
      Cell: rowData => {
        const { name, pdf, dwg } = rowData.row.original;
        if (
          rowData.row.original.name === 'Battersea Development Site Rules .pdf'
        ) {
        }
        return (
          <>
            <div
              className="hover-grey"
              title={name}
              onClick={() => {
                pdf
                  ? onFile(pdf, 'pdf', rowData.row.original)
                  : onFile(dwg, 'dwg', rowData.row.original);
              }}
            >
              {(name.length > 35 && isSmallTablet) ||
              (name.length > 45 && isTablet) ||
              name.length > 70
                ? truncateFileName(name)
                : name}
            </div>
          </>
        );
      }
    },
    {
      accessor: 'pdf',
      Header: 'PDF',
      Cell: rowData => {
        const { pdf } = rowData.row.original;
        return (
          <>
            <div
              style={{ opacity: pdf ? 1 : 0.5 }}
              className="cursor-pointer text-center"
              onClick={() => onFile(pdf, 'pdf', rowData.row.original)}
            >
              {getIcon({
                icon_component: 'fi/FiFileText',
                icon_component_attributes: { fontSize: 30, marginRight: 0 }
              })}
            </div>
          </>
        );
      }
    },
    {
      accessor: 'dwg',
      Header: 'DWG',
      Cell: rowData => {
        const { dwg } = rowData.row.original;
        return (
          <div
            style={{ opacity: dwg ? 1 : 0.5 }}
            className="cursor-pointer text-center"
            onClick={() => onFile(dwg, 'dwg', rowData.row.original)}
          >
            {getIcon({
              icon_component: 'fi/FiFileText',
              icon_component_attributes: { fontSize: 30, marginRight: 0 }
            })}
          </div>
        );
      }
    }
  ];

  return (
    <div className="extended-table mb-3">
      <AdvanceTableWrapper
        columns={columns}
        data={setTableData(item)}
        sortable={false}
        perPage={item.length + 1}
      >
        <AdvanceTable
          table
          headerClassName="bg-200 text-700 align-middle fs-0 custom-header text-center"
          rowClassName="align-middle white-space-nowrap fs-0"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0'
          }}
        />
      </AdvanceTableWrapper>
    </div>
  );
};
export default AccordionTable;
