import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'utils/endpoints/endpoints';
import https from 'utils/https';

export const getContentData = createAsyncThunk(
  'content/relatedContentData',
  async params => {
    try {
      const response = await https.get(
        `${ENDPOINTS.GET_CONTENT}?userISbb_usersID=${params.id}`
      );

      return response.data.list;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  content: null,
  status: 'idle',
  approvingContent: null
};

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    setContentData(state, action) {
      state.content = action.payload;
    },
    setApprovingContent(state, action) {
      state.approvingContent = action.payload;
    },
    resetContentData(state) {
      state.content = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getContentData.pending, state => {
        state.contentStatus = 'loading';
      })
      .addCase(getContentData.fulfilled, (state, action) => {
        state.contentStatus = 'succeeded';
        state.content = action.payload;
      })
      .addCase(getContentData.rejected, (state, action) => {
        state.contentStatus = 'failed';
        state.error = action.error.message;
      });
  }
});

export const { setContentData, resetContentData, setApprovingContent } =
  contentSlice.actions;

export default contentSlice.reducer;
