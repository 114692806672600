import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { setError } from 'redux/slicers/appsettingSlice';
import axios from 'axios';
import endpoint from 'services/endpoint';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getErrorMesssage } from 'services/axiosHelpers';

const EditModal = ({ show, isAdd, user, handleClose, success }) => {
  const _isMounted = useRef(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const title = isAdd ? t('New User') : t('Edit User');
  const btnCaption = t('Save');
  const appsetting = useSelector(state => state.appsetting);
  const { user_type, user_status } = appsetting.dropdowns;
  const userTypes = [...user_type];
  const regions = appsetting.regions || [];
  const languages = appsetting.languages || [];
  const statuses = [...user_status];
  const defaultValue = '';

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    on_holdYN: '',
    user_type: '',
    regionISbb_q4sg_regionsID: '',
    languageISbb_q4sg_languagesID: '',
    password: ''
  });

  const isDisabled = () => {
    let result = true;
    if (
      formData.first_name &&
      formData.last_name &&
      formData.email &&
      formData.on_holdYN &&
      formData.user_type &&
      formData.regionISbb_q4sg_regionsID &&
      formData.languageISbb_q4sg_languagesID
    ) {
      result = false;
    }
    return result;
  };

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const setup = () => {
    let userType = '';
    if (!isAdd) {
      if (user.user_type) {
        const type = userTypes.find(t => t.name === user.user_type);
        if (type) {
          userType = type._id;
        }
      }
    }
    const initFormData = {
      first_name: isAdd ? '' : user.first_name,
      last_name: isAdd ? '' : user.last_name,
      email: isAdd ? '' : user.email,
      on_holdYN: isAdd ? '' : user.on_holdYN || '',
      user_type: userType,
      regionISbb_q4sg_regionsID: isAdd
        ? ''
        : user.regionISbb_q4sg_regionsID || '',
      languageISbb_q4sg_languagesID: isAdd
        ? ''
        : user.languageISbb_q4sg_languagesID || '',
      password: isAdd ? '' : user.password || ''
    };
    setFormData(initFormData);
  };

  const save = async () => {
    try {
      _isMounted.current && setLoading(true);
      let data = formData;

      const ep = isAdd
        ? endpoint.addUserEndpoint
        : endpoint.getEditUserEndpoint(user._id);
      const res = isAdd
        ? await axios.post(ep, data)
        : await axios.patch(ep, data);

      if (res.data.error) {
        const message = getErrorMesssage(res.data.error);
        _isMounted.current &&
          dispatch(
            setError({
              isShow: true,
              title: 'Error',
              message
            })
          );
      } else {
        _isMounted.current && success();
        _isMounted.current && handleClose();
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
    } finally {
      _isMounted.current && setLoading(false);
    }
  };

  const handleApprove = async () => {
    setFormData({ ...formData, ...(formData.on_holdYN = '0') });
    try {
      _isMounted.current && setLoading(true);
      let data = formData;

      const ep = endpoint.getEditUserEndpoint(user._id);
      const res = await axios.patch(ep, data);

      if (res.data.error) {
        const message = getErrorMesssage(res.data.error);
        _isMounted.current &&
          dispatch(
            setError({
              isShow: true,
              title: 'Error',
              message
            })
          );
      } else {
        _isMounted.current && success();
        _isMounted.current && handleClose();
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
    } finally {
      _isMounted.current && setLoading(false);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    await save();
  };

  useEffect(() => {
    _isMounted.current = true;
    if (show) {
      setup();
    }
    return () => {
      _isMounted.current = false;
    };
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        contentClassName="border-0"
        centered
        size="lg"
      >
        <Modal.Header className="bg-modal-header light border-bottom-0">
          <FalconCloseButton noOutline onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <fieldset className={'form-legend-wrapper pb-6 px-4'}>
            <legend className="item-title fs-1 fw-semi-bold">{t(title)}</legend>
            <Form onSubmit={handleSubmit} className="px-6 pt-4">
              <Row>
                <Col>
                  <fieldset className={'form-legend-item-wrapper mb-4'}>
                    <legend className="item-title fs-1 fw-semi-bold">
                      {t('First Name')}
                    </legend>
                    <Form.Group className="mb-0">
                      <Form.Control
                        placeholder={''}
                        value={formData.first_name}
                        name="first_name"
                        onChange={handleChange}
                        type="text"
                      />
                    </Form.Group>
                  </fieldset>
                </Col>
                <Col>
                  <fieldset className={'form-legend-item-wrapper mb-4'}>
                    <legend className="item-title fs-1 fw-semi-bold">
                      {t('Last Name')}
                    </legend>
                    <Form.Group className="mb-0">
                      <Form.Control
                        placeholder={''}
                        value={formData.last_name}
                        name="last_name"
                        onChange={handleChange}
                        type="text"
                      />
                    </Form.Group>
                  </fieldset>
                </Col>
              </Row>

              <Row>
                <Col>
                  <fieldset className={'form-legend-item-wrapper mb-4'}>
                    <legend className="item-title fs-1 fw-semi-bold">
                      {t('Email')}
                    </legend>
                    <Form.Group className="mb-0">
                      <Form.Control
                        placeholder={''}
                        value={formData.email}
                        name="email"
                        onChange={handleChange}
                        type="email"
                      />
                    </Form.Group>
                  </fieldset>
                </Col>
                <Col>
                  <fieldset className={'form-legend-item-wrapper'}>
                    <legend className="item-title fs-1 fw-semi-bold">
                      {t('Status')}
                    </legend>
                    <Form.Control
                      as="select"
                      value={formData.on_holdYN}
                      name="on_holdYN"
                      onChange={handleChange}
                      required
                      className="form-select"
                    >
                      <option value={defaultValue}>{t('Select Status')}</option>
                      {statuses.map((status, index) => (
                        <option value={status._id} key={index}>
                          {status.name}
                        </option>
                      ))}
                    </Form.Control>
                  </fieldset>
                </Col>
              </Row>

              <Row>
                <Col>
                  <fieldset className={'form-legend-item-wrapper mb-4'}>
                    <legend className="item-title fs-1 fw-semi-bold">
                      {t('User Type')}
                    </legend>
                    <Form.Control
                      as="select"
                      value={formData.user_type}
                      name="user_type"
                      onChange={handleChange}
                      required
                      className="form-select"
                    >
                      <option value={defaultValue}>
                        {t('Select User Type')}
                      </option>
                      {userTypes.map((type, index) => (
                        <option value={type._id} key={index}>
                          {type.name}
                        </option>
                      ))}
                    </Form.Control>
                  </fieldset>
                </Col>
                <Col>
                  <fieldset className={'form-legend-item-wrapper mb-4'}>
                    <legend className="item-title fs-1 fw-semi-bold">
                      {t('Region')}
                    </legend>
                    <Form.Control
                      as="select"
                      value={formData.regionISbb_q4sg_regionsID}
                      name="regionISbb_q4sg_regionsID"
                      onChange={handleChange}
                      required
                      className="form-select"
                    >
                      <option value={defaultValue}>{t('Select Region')}</option>
                      {regions.map((region, index) => (
                        <option value={region._id} key={index}>
                          {region.name}
                        </option>
                      ))}
                    </Form.Control>
                  </fieldset>
                </Col>
              </Row>

              <Row>
                <Col>
                  <fieldset className={'form-legend-item-wrapper mb-4'}>
                    <legend className="item-title fs-1 fw-semi-bold">
                      {t('Language')}
                    </legend>
                    <Form.Control
                      as="select"
                      value={formData.languageISbb_q4sg_languagesID}
                      name="languageISbb_q4sg_languagesID"
                      onChange={handleChange}
                      required
                      className="form-select"
                    >
                      <option value={defaultValue}>
                        {t('Select Language')}
                      </option>
                      {languages.map((language, index) => (
                        <option value={language._id} key={index}>
                          {language.name}
                        </option>
                      ))}
                    </Form.Control>
                  </fieldset>
                </Col>
                {user?.on_holdYN !== '1' && (
                  <Col>
                    <fieldset className={'form-legend-item-wrapper mb-4'}>
                      <legend className="item-title fs-1 fw-semi-bold">
                        {t('Password')}
                      </legend>
                      <Form.Group className="mb-0">
                        <Form.Control
                          placeholder={''}
                          value={formData.password}
                          name="password"
                          onChange={handleChange}
                          type="password"
                        />
                      </Form.Group>
                    </fieldset>
                  </Col>
                )}
              </Row>

              <div className="text-end d-flex gap-5">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={loading || isDisabled()}
                >
                  {loading ? `${t('Saving')}...` : btnCaption}
                </Button>
                {user.on_holdYN === '1' && (
                  <Button
                    variant="primary"
                    type="button"
                    disabled={loading}
                    onClick={handleApprove}
                  >
                    {loading ? `${t('Saving')}...` : 'Approve this user'}
                  </Button>
                )}
              </div>
            </Form>
          </fieldset>
        </Modal.Body>
      </Modal>
    </>
  );
};

EditModal.propTypes = {
  show: PropTypes.bool,
  isAdd: PropTypes.bool,
  user: PropTypes.object,
  handleClose: PropTypes.func,
  success: PropTypes.func
};

export default EditModal;
