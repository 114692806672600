import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import https from 'utils/https';
import { ENDPOINTS } from 'utils/endpoints/endpoints';
import { setError } from 'redux/slicers/appsettingSlice';

async function loginPost(body) {
  const { data } = await https.post(ENDPOINTS.LOGIN, body);
  return data;
}

export const useLoginMutation = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: input => loginPost(input),
    onError: error => {
      dispatch(
        setError({
          isShow: true,
          title: error?.response?.data?.error ?? 'Incorrect Email or Password',
          message: 'Please submit correct Email and Password'
        })
      );
    }
  });
};
