import React, { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import PageTitle from 'components/common/PageTitle';
import { Tab, Nav } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import Flex from 'components/common/Flex';
import { useTranslation } from 'react-i18next';
import { setInit, setError } from 'redux/slicers/appsettingSlice';
import axios from 'axios';
import endpoint from 'services/endpoint';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';
import Loading from 'components/loading';
import { useNavigate, Link } from 'react-router-dom';
import { getErrorMesssage } from 'services/axiosHelpers';
import { getContentUrl, getAllContentsFormTree } from 'helpers/utils';

const Reports = () => {
  const [users, setUsers] = useState(null);

  const _isMounted = useRef(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const appsetting = useSelector(state => state.appsetting);
  const category_tree = appsetting.category_tree;
  const authUser = useSelector(state => state.auth.user);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [kind, setKind] = useState('usage-report');

  // const [contents, setContents] = useState([]);
  // const [reports, setReports] = useState([]);

  const title = 'Reports';

  const kinds = [
    {
      key: 'usage-report',
      name: t('UsageReport')
    },
    {
      key: 'listing',
      name: t('Content Listing')
    }
  ];

  const contentListingData = useMemo(() => {
    const contents = getAllContentsFormTree(category_tree, '', '');
    const result = contents.filter(c => c.type === 'content');
    return result;
  }, [category_tree]);

  const usersOnHold = useMemo(
    () => users?.filter(user => user.on_holdYN === '0'),
    [users]
  );

  const contentListingColumns = useMemo(() => {
    let result = [];
    result = [
      {
        accessor: 'location',
        Header: t('Location'),
        Cell: rowData => {
          const { location } = rowData.row.original;
          return <>{location}</>;
        }
      },
      {
        accessor: 'title',
        Header: t('Title'),
        Cell: rowData => {
          const { link, title, type } = rowData.row.original;
          const url = getContentUrl(link, type);
          return (
            <>
              {url ? (
                <Link
                  to={url}
                  className="link text-decoration-underline"
                  target="_blank"
                >
                  {title}
                </Link>
              ) : (
                <>{title}</>
              )}
            </>
          );
        }
      }
    ];
    return result;
  }, [contentListingData]);

  const usersColumn = useMemo(() => {
    let result = [];
    result = [
      {
        accessor: 'first_name',
        Header: t('First Name')
      },
      {
        accessor: 'email',
        Header: t('Email')
      },
      {
        accessor: 'user_type',
        Header: t('Type')
      },
      {
        accessor: 'region',
        Header: t('Region')
      },
      {
        accessor: 'last_login',
        Header: t('LastLogged')
      },
      {
        accessor: 'login_count',
        Header: t('TimesLogged'),
        Cell: rowData => {
          const { login_count } = rowData.row.original;
          if (login_count == 0) {
            return <>Never logged in</>;
          } else {
            return <>{login_count}</>;
          }
        }
      }
    ];
    return result;
  }, [usersOnHold]);

  const handlerAppSettingData = async () => {
    _isMounted.current && setLoading(true);
    try {
      const response = await axios.get(endpoint.settings);
      console.log('-----app_settings----at Admin---------', response.data);
      const data = response.data;

      const background_color = data.background_color;
      const category_tree = data.category_tree;
      const default_route = data.default_route;
      const dropdowns = data.dropdowns;
      const env_files = data.env_files;
      const favicon_16ISfile = data.favicon_16ISfile;
      const favicon_32ISfile = data.favicon_32ISfile;
      const favicon_192ISfile = data.favicon_192ISfile;
      const languages = data.languages;
      const logoISfile = data.logoISfile;
      const name = data.name;
      const regions = data.regions;
      const secondary_color = data.secondary_color;
      const splash_logoISfile = data.splash_logoISfile;
      const title = data.title;
      const translations = data.translations;

      dispatch(
        setInit({
          background_color,
          category_tree,
          default_route,
          dropdowns,
          env_files,
          favicon_16ISfile,
          favicon_32ISfile,
          favicon_192ISfile,
          languages,
          logoISfile,
          name,
          regions,
          secondary_color,
          splash_logoISfile,
          title,
          translations
        })
      );
    } catch (err) {
      const message = getErrorMesssage(err);
      dispatch(
        setError({
          isShow: true,
          title: 'Error',
          message
        })
      );
    } finally {
      _isMounted.current && setLoading(false);
    }
  };

  const setup = async () => {
    // To be removed
    if (authUser.user_type !== 'Admin') {
      await handlerAppSettingData();
    }
  };

  useEffect(() => {
    _isMounted.current = true;
    if (authUser.user_type === 'Admin') {
      setup();
    } else {
      navigate('/home');
    }
    return () => {
      _isMounted.current = false;
    };
  }, []);

  const getUsers = async userType => {
    try {
      _isMounted.current && setLoading(true);
      const ep = `${endpoint.getUsersEndpoint(userType)}`;
      const usersRes = await axios.get(ep);
      const usersData = usersRes.data;

      if (users?.length > 0) {
        setUsers(users.concat(usersData));
      } else {
        setUsers(usersData);
      }

      if (usersData.error) {
        const message = getErrorMesssage(usersData.error);
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
        return [];
      } else {
        return usersData;
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      dispatch(
        setError({
          isShow: true,
          title: 'Error',
          message
        })
      );
      return [];
    } finally {
      _isMounted.current && setLoading(false);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const getTeams = await getUsers(2);
      const getSupp = await getUsers(3);

      const result = [...getTeams, ...getSupp];
      setUsers(result);
    };
    fetch();
  }, []);

  return (
    <>
      <div>
        <PageTitle title={title} />
        {loading ? (
          <Card.Body className="">
            <Loading style={{ marginTop: 50 }} msg="Loading Data..." />
          </Card.Body>
        ) : (
          <div className="page-content pt-6 mb-6">
            <Tab.Container
              activeKey={kind}
              onSelect={k => setKind(k)}
              id="countries-tab"
              transition={false}
            >
              <Flex justifyContent="between" alignItems="center" className="">
                <Nav variant="tabs" className="flex-row mb-3">
                  {kinds.map((c, index) => (
                    <Fragment key={index}>
                      <Nav.Item>
                        <Nav.Link
                          eventKey={c.key}
                          className="fw-semi-bold fs-1"
                        >
                          {t(c.name)}
                        </Nav.Link>
                      </Nav.Item>
                    </Fragment>
                  ))}
                </Nav>
              </Flex>
              <Tab.Content>
                {kinds.map((c, index) => (
                  <Fragment key={index}>
                    <Tab.Pane eventKey={c.key} title={c.name} className="p-3">
                      {kind === 'usage-report' && users && (
                        <div className="extended-table">
                          <AdvanceTableWrapper
                            columns={usersColumn}
                            data={usersOnHold}
                            sortable
                            pagination
                            perPage={20}
                          >
                            <AdvanceTable
                              table
                              headerClassName="bg-200 text-700 text-nowrap align-middle fs-0"
                              rowClassName="align-middle white-space-nowrap fs-0"
                              tableProps={{
                                bordered: true,
                                striped: true,
                                className: 'fs--1 mb-0'
                              }}
                            />
                            <div className="mt-3">
                              <AdvanceTablePagination table />
                            </div>
                          </AdvanceTableWrapper>
                        </div>
                      )}
                      {kind === 'listing' && (
                        <div className="extended-table">
                          <AdvanceTableWrapper
                            columns={contentListingColumns}
                            data={contentListingData}
                            sortable
                            pagination
                            perPage={20}
                          >
                            <AdvanceTable
                              table
                              headerClassName="bg-200 text-700 text-nowrap align-middle fs-0"
                              rowClassName="align-middle white-space-nowrap fs-0"
                              tableProps={{
                                bordered: true,
                                striped: true,
                                className: 'fs--1 mb-0'
                              }}
                            />
                            <div className="mt-3">
                              <AdvanceTablePagination table />
                            </div>
                          </AdvanceTableWrapper>
                        </div>
                      )}
                    </Tab.Pane>
                  </Fragment>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        )}
      </div>
    </>
  );
};

export default Reports;
