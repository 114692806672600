import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { getIcon } from 'helpers/utils';
import { useLogoutMutation } from 'utils/rest/auth/logout';

const ProfileDropdown = () => {
  const { t } = useTranslation();
  const { user, isAuthenticated } = useSelector(state => state.auth);
  const { mutate: logout } = useLogoutMutation();

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      {isAuthenticated && (
        <Dropdown navbar={true} as="li">
          <Dropdown.Toggle
            bsPrefix="toggle"
            as={Link}
            to="#!"
            className="pe-0 ps-0 nav-link"
          >
            <Flex justifyContent="between" alignItems="center" className="">
              <span className="me-2">
                {user?.first_name || user?.user?.first_name}
              </span>
              <span>
                {getIcon({
                  icon_component: 'fa/FaUser',
                  icon_component_attributes: { fontSize: 20, marginRight: 0 }
                })}
              </span>
            </Flex>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
            <div className="bg-white rounded-2 py-2 dark__bg-1000">
              {(user?.user_type === 'Admin' ||
                user?.user?.user_type === 'Admin') && (
                <>
                  <Dropdown.Item as={Link} to="/admin">
                    {t('Admin Area')}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item as={Link} to="/reports">
                    {t('Reports')}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                </>
              )}

              {(user?.user_type === 'Team' ||
                user?.user?.user_type === 'Team' ||
                user?.user_type === 'Supplier' ||
                user?.user?.user_type === 'Supplier') && (
                <>
                  <Dropdown.Item as={Link} to="/account?tab=my-account">
                    {t('MyAccount')}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item as={Link} to="/account?tab=my-content">
                    {t('MyContent')}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                </>
              )}
              <Dropdown.Item>
                <Flex justifyContent="start" alignItems="center">
                  <span className="" onClick={handleLogout}>
                    {t('Sign out')}
                  </span>
                </Flex>
              </Dropdown.Item>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
};

ProfileDropdown.propTypes = {
  isAuthenticated: PropTypes.bool,
  type: PropTypes.string
};

export default ProfileDropdown;
