const preffix = '/api/v1';

export const ENDPOINTS = {
  SESSION: `${preffix}/users/session`,
  LOGIN: `${preffix}/users/login`,
  LOGOUT: `${preffix}/users/logout`,
  UPLOAD_CONTENT: `${preffix}/module/bb_q4sg_content_uploads`,
  UPLOAD_SUB_CONTENT: `${preffix}/module/bb_q4sg_sub_content`,
  GET_CONTENT: `${preffix}/module/bb_q4sg_content_uploads`,
  GET_PDF: `${preffix}/app/getPDF?pdfURL=`,
  TRACKING: `${preffix}/app/click_counter`,
  CONTENT: `${preffix}/module/bb_q4sg_content/`
};
