import { useSelector } from 'react-redux';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { useEffect, useMemo, useState } from 'react';
import CardDropdown from 'components/common/CardDropdown';
import { useTranslation } from 'react-i18next';
import Actions from './actions/Action';
import Rejected from './actions/Rejected';
import { useGetContentMutation } from 'utils/rest/content/getContent';
import Loading from 'components/loading';
import { Link } from 'react-router-dom';

const Uploaded = () => {
  const { t } = useTranslation();

  const { content } = useSelector(state => state.content);
  const { mutate: getContent, isPending } = useGetContentMutation();
  const { user } = useSelector(state => state.auth);

  const ActionButtons = ({ data, content }) => {
    return (
      <CardDropdown iconClassName="fs--1">
        <div className="py-2">
          {data === 'rejected' ? <Rejected content={content} /> : <Actions />}
        </div>
      </CardDropdown>
    );
  };

  const columns = useMemo(() => {
    const result = [
      {
        accessor: 'name',
        Header: t('Name'),
        Cell: rowData => {
          const data = rowData.row.original;
          if (data.status === 'In Review') {
            return (
              <Link target="_blank" to={data.content.urlISplaintextbox}>
                {data.name}
              </Link>
            );
          } else {
            return <>{data.name}</>;
          }
        }
      },
      {
        accessor: 'path',
        Header: 'Category'
      },
      {
        accessor: 'date_added',
        Header: t('dateUploaded')
      },
      {
        accessor: 'status',
        Header: t('Status'),
        Cell: rowData => {
          const rejectedData = rowData.row.original.status === 'Rejected';
          const pendingData = rowData.row.original.status === 'Processing';

          if (rejectedData) {
            return (
              <div className="text-center d-flex align-items-center gap-2">
                {rowData.row.original.status}
                <ActionButtons
                  data={'rejected'}
                  content={rowData.row.original.content}
                />
              </div>
            );
          }

          return rowData.row.original.status;
        }
      }
    ];
    return result;
  }, [content]);

  const contentData = useMemo(() => {
    const results = content?.map(c => {
      const date = c._dateadded.split('T')[0].replace(/-/g, '/');
      const time = c._dateadded.split('T')[1].split('+')[0];

      let status;

      if (!c.preview_importedYN) {
        status = 'Processing';
      } else if (c.preview_importedYN) {
        status = 'In Review';
      }
      if (c.approvedYN) {
        status = 'Approved';
      } else if (c.rejectedYN) {
        status = 'Rejected';
      }

      return {
        content: c,
        _id: c._id,
        name: c.name,
        date_added: `${date} ${time}`,
        status: status,
        path: c.selected_pathISplaintextbox
      };
    });

    return results;
  }, [content]);

  useEffect(() => {
    getContent(user?._id);
  }, []);

  if (isPending)
    return (
      <Loading style={{ marginTop: 0, height: '100%' }} className="d-flex" />
    );

  return (
    <div>
      {(!contentData || contentData?.length === 0) && (
        <div className="d-flex justify-content-center content-border">
          <span className="fs-18">
            You have no content submitted - click 'Add Content' to start
          </span>
        </div>
      )}
      {contentData && contentData?.length > 0 && (
        <div className="extended-table">
          <AdvanceTableWrapper
            columns={columns}
            data={contentData}
            sortable
            pagination
            perPage={10}
          >
            <AdvanceTable
              table
              headerClassName="bg-200 text-700 text-nowrap align-middle fs-0"
              rowClassName="align-middle white-space-nowrap fs-0"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0'
              }}
            />

            <div className="mt-3">
              <AdvanceTablePagination table />
            </div>
          </AdvanceTableWrapper>
        </div>
      )}
    </div>
  );
};

export default Uploaded;
