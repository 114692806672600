import React, { useMemo } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionTable from './AccordionTable';
import AccordionItemNested from './AccordionItemNested';
import { truncateFolderName } from './helpers';
// import useMobileScreenSize from 'hooks/useMobileScreenSize';
import useTabletScreenSize from 'hooks/useTabletScreenSize';

const AccordionItem = ({ data, parent, contentId, content }) => {
  const isTablet = useTabletScreenSize();

  // function for sorting items with name - 'Section 1, Section 2 etc'
  const getSectionNumber = name => {
    const match = name.match(/Section (\d+)/);
    return match ? parseInt(match[1]) : Infinity;
  };

  const accordionData = useMemo(() => {
    const itemData = { ...data };
    delete itemData.data;
    delete itemData.name;
    const newData = Object.values(itemData);

    newData.sort((a, b) => {
      if (b.name === 'Popular Documents') {
        return getSectionNumber(b.name) - getSectionNumber(a.name);
      } else {
        return getSectionNumber(a.name) - getSectionNumber(b.name);
      }
    });
    return newData;
  }, [data]);

  return (
    <Accordion className="">
      {accordionData?.length > 0 &&
        accordionData?.map((item, index) => (
          <Accordion.Item eventKey={index} key={index}>
            <Accordion.Header>
              {item?.name?.length > 100 || isTablet
                ? truncateFolderName(item?.name)
                : item?.name}
            </Accordion.Header>
            <Accordion.Body>
              {item?.data?.length > 0 && (
                <AccordionTable
                  parent={parent}
                  item={item.data}
                  contentId={contentId}
                  content={content}
                />
              )}
              {item && Object.values(item)?.length && (
                <div className="">
                  <AccordionItemNested
                    parent={parent}
                    data={item}
                    contentId={contentId}
                    content={content}
                  />
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        ))}
    </Accordion>
  );
};

export default AccordionItem;
