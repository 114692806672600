export const resources = {
  GB: {
    translation: {
      LastLogged: 'Last Logged In',
      TimesLogged: 'Times Logged In',
      UsageReport: 'Usage Report',
      accessText: 'Request Access to Toolkit',
      forgottenText: 'Request a password reset link',
      actioned: 'Actioned',
      approve: 'Approve',
      reject: 'Reject',
      reSubmit: 'Resubmit Content',
      rejected: 'Show comment',
      uploaded: 'Uploaded',
      approval: 'Awaiting Approval',
      dateUploaded: 'Date Uploaded',
      contentFormDefault: 'Select Fixtures and Furniture Category',
      MyContent: 'My Content',
      NoContent:
        ' You have no content submitted - click "Add Content" to start',
      'Add current page': 'Add current page',
      Help: 'Help',
      'Contact Us': 'Contact Us',
      'Sky Website': 'Sky Website',
      'Sky Group': 'Sky Group',
      'Quick Links': 'Quick Links',
      News: 'News',
      Archive: 'Archive',
      Briefing: 'Briefing',
      Projects: 'Projects',
      Contacts: 'Contacts',
      'Add a page link': 'Add a page link',
      Shop: 'Shop',
      'Mid Mall': 'Mid Mall',
      'My Account': 'My Account',
      'Help & Support': 'Help & Support',
      Settings: 'Settings',
      'Welcome to the': 'Welcome to the',
      Welcome: 'Welcome',
      'Please Login': 'Please Login',
      Email: 'Email',
      Password: 'Password',
      Submit: 'Submit',
      'Password Reset': 'Password Reset',
      'Request Access': 'Request Access',
      'Sign Up': 'Sign Up',
      Login: 'Login',
      Company: 'Company',
      Contact: 'Contact',
      Category: 'Category',
      'Tel.': 'Tel.',
      'Web.': 'Web.',
      UK: 'UK',
      Germany: 'Germany',
      Austria: 'Austria',
      Italia: 'Italia',
      'Project Name': 'Project Name',
      'Date Created': 'Date Created',
      'Store Plans': 'Store Plans',
      'Rendered Visuals': 'Rendered Visuals',
      'Meeting Minutes': 'Meeting Minutes',
      'Project Status': 'Project Status',
      'Project Owner': 'Project Owner',
      'Back to Login': 'Back to Login',
      Portfolio: 'Portfolio',
      'Show More': 'Show More',
      'Show Less': 'Show Less',
      'Briefing Form': 'Briefing Form',
      'System Administration': 'System Administration',
      Edit: 'Edit',
      Delete: 'Delete',
      View: 'View',
      Name: 'Name',
      Region: 'Region',
      Status: 'Status',
      Action: 'Action',
      Users: 'Users',
      Categories: 'Categories',
      Content: 'Content',
      Reports: 'Reports',
      Login: 'Log in',
      'Incorrect Email or Password': 'Incorrect Email or Password',
      'Store Plan': 'Store Plan',
      'Territory Delivery': 'Territory Delivery',
      'Sign out': 'Sign out',
      'My projects': 'My projects',
      Messages: 'Messages',
      Details: 'Details',
      Close: 'Close',
      Error: 'Error',
      'First Name': 'First Name',
      'Last Name': 'Last Name',
      Mobile: 'Mobile',
      Message: 'Message',
      'User Type': 'User Type',
      Shops: 'Shops',
      Processes: 'Processes',
      'Design Guidelines': 'Design Guidelines',
      'Best Practise': 'Best Practise',
      'Live Stores': 'Live Stores',
      ROI: 'ROI',
      Cardiff: 'Cardiff',
      Edinburgh: 'Edinburgh',
      Liverpool: 'Liverpool',
      Birmingham: 'Birmingham',
      'White City': 'White City',
      Battersea: 'Battersea',
      Home: 'Home',
      Back: 'Back',
      'Reset password': 'Reset password',
      Token: 'Token',
      Admin: 'Admin',
      MyAccount: 'My Account',
      '3rd Party & Tactical': '3rd Party & Tactical',
      'Merch Guidelines': 'Merch Guidelines',
      'Store Furniture Information': 'Store Furniture Information',
      'Project History': 'Project History',
      'New User': 'New User',
      Save: 'Save',
      Language: 'Language',
      Type: 'Type',
      'Add User': 'Add User',
      'Edit User': 'Edit User',
      'Add Category': 'Add Category',
      Furniture: 'Furniture',
      'Principles & Design Guidelines': 'Principles & Design Guidelines',
      'Form Briefing Form': 'Form Briefing Form',
      'New Project Process Briefing': 'New Project Process Briefing',
      'Brefing & New Project Process': 'Brefing & New Project Process',
      'Add Content': 'Add Content',
      'Edit Content': 'Edit Content',
      'Refer to': 'Refer to',
      Summary: 'Summary',
      Image: 'Image',
      'New Content': 'New Content',
      'New Category': 'New Category',
      Title: 'Title',
      'Document/Link': 'Document/Link',
      'Sub Contents': 'Sub Contents',
      'Principles & design guidelines': 'Principles & design guidelines',
      'Best practise': 'Best practise',
      'Design guidelines': 'Design guidelines',
      Stores: 'Stores',
      'Mid mall': 'Mid mall',
      'Edit Category': 'Edit Category',
      'Delete User': 'Delete User',
      'Are you sure you want to delete this user?':
        'Are you sure you want to delete this user?',
      'Yes, Delete': 'Yes, Delete',
      'Go Back': 'Go Back',
      'Select Status': 'Select Status',
      'Select User Type': 'Select User Type',
      'Select Region': 'Select Region',
      'Select Language': 'Select Language',
      'Select category': 'Select category',
      'Delete Category': 'Delete Category',
      'Are you sure you want to delete this category?':
        'Are you sure you want to delete this category?',
      Document: 'Document',
      'Video URL': 'Video URL',
      'External Link': 'External Link',
      Add: 'Add',
      "Let's get started": "Let's get started",
      'Please enter your email or username':
        'Please enter your email or username',
      'Email or Username': 'Email or Username',
      'Forgotten your email, username or password?':
        'Forgotten your email, username or password?',
      'Request a password reset link': 'Request a password reset link',
      Continue: 'Continue',
      'No elements in this Portfolio': 'No elements in this Portfolio',
      'This section is under construction':
        'This section is under construction, come back soon to discover more...',
      'Fixtures & Furniture': 'Fixtures & Furniture',
      Accessories: 'Accessories',
      Booth: 'Booth',
      'Mid Floor': 'Mid Floor',
      Walls: 'Walls',
      Windows: 'Windows',
      Documents: 'Documents',
      'Scroll to bottom for documents': 'Scroll to bottom for documents',
      'Unit Dimensions': 'Unit Dimensions',
      Info: 'Info',
      Materials: 'Materials',
      'End of Life': 'End of Life',
      'Admin Area': 'Admin Area'
    }
  },
  DE: {
    translation: {
      'Add current page': 'Aktuelle Seite hinzufügen',
      Help: 'Hilfe',
      'Contact Us': 'Kontaktiere uns',
      'Sky Website': 'Sky-Website',
      'Sky Group': 'Himmelsgruppe',
      'Quick Links': 'Schnelllinks',
      News: 'Nachricht',
      news: 'Nachricht',
      Archive: 'Archiv',
      Briefing: 'Einweisung',
      Projects: 'Projekte',
      Contacts: 'Kontakte',
      'Add a page link': 'Seitenlink hinzufügen',
      Shop: 'Geschäft',
      'Mid Mall': 'Mittleres Einkaufszentrum',
      'My Account': 'Mein Konto',
      'Help & Support': 'Hilfe Unterstützung',
      Settings: 'Einstellungen',
      'Welcome to the': 'Willkommen beim',
      Welcome: 'Willkommen',
      'Please Login': 'Bitte loggen Sie sich ein',
      Email: 'Email',
      Password: 'Passwort',
      Submit: 'Einreichen',
      'Password Reset': 'Passwort zurücksetzen',
      'Request Access': 'Anfrage Zugang',
      'Sign Up': 'Anmeldung',
      Login: 'Anmeldung',
      Company: 'Unternehmen',
      Contact: 'Kontakt',
      Category: 'Kategorie',
      'Tel.': 'Tel.',
      'Web.': 'Netz.',
      UK: 'VEREINIGTES KÖNIGREICH',
      Germany: 'Deutschland',
      Austria: 'Österreich',
      Italia: 'Italien',
      'Project Name': 'Projektname',
      'Date Created': 'Datum erstellt',
      'Store Plans': 'Store-Pläne',
      'Rendered Visuals': 'Gerenderte Visualisierungen',
      'Meeting Minutes': 'Sitzungsprotokoll',
      'Project Status': 'Projekt-Status',
      'Project Owner': 'Projekteigentümer',
      'Back to Login': 'Zurück zur Anmeldung',
      Portfolio: 'Portfolio',
      'Show More': 'Zeig mehr',
      'Show Less': 'Zeige weniger',
      'Briefing Form': 'Briefing-Formular',
      'System Administration': 'Systemadministration',
      Edit: 'Bearbeiten',
      Delete: 'Löschen',
      View: 'Sicht',
      Name: 'Name',
      Region: 'Region',
      Status: 'Status',
      Action: 'Aktion',
      Users: 'Benutzer',
      Categories: 'Kategorien',
      Content: 'Inhalt',
      Reports: 'Berichte',
      'Log in': 'Anmeldung',
      'Incorrect Email or Password': 'Falsche Email oder Passwort',
      'Store Plan': 'Speicherplan',
      'Territory Delivery': 'Gebietszustellung',
      'Sign out': 'Austragen',
      'My projects': 'Meine Projekte',
      Messages: 'Mitteilungen',
      Details: 'Einzelheiten',
      Close: 'Schließen',
      Error: 'Fehler',
      'First Name': 'Vorname',
      'Last Name': 'Familienname, Nachname',
      Mobile: 'Handy, Mobiltelefon',
      Message: 'Nachricht',
      'User Type': 'Benutzertyp',
      Shops: 'Geschäfte',
      Processes: 'Prozesse',
      'Design Guidelines': 'Designrichtlinien',
      'Best Practise': 'Beste Übung',
      'Live Stores': 'Live-Shops',
      ROI: 'ROI',
      Cardiff: 'Cardiff',
      Edinburgh: 'Edinburgh',
      Liverpool: 'Liverpool',
      Birmingham: 'Birmingham',
      'White City': 'Weiße Stadt',
      Battersea: 'Battersea',
      Home: 'Heim',
      Back: 'Zurück',
      'Reset password': 'Passwort zurücksetzen',
      Token: 'Zeichen',
      Admin: 'Administrator',
      '3rd Party & Tactical': 'Drittanbieter & taktisch',
      'Merch Guidelines': 'Merch-Richtlinien',
      'Store Furniture Information': 'Möbelinformationen speichern',
      'Project History': 'Projektverlauf',
      'New User': 'Neuer Benutzer',
      Save: 'Speichern',
      Language: 'Sprache',
      Type: 'Typ',
      'Add User': 'Benutzer hinzufügen',
      'Edit User': 'Benutzer bearbeiten',
      'Add Category': 'Kategorie hinzufügen',
      Furniture: 'Möbel',
      'Principles & Design Guidelines': 'Prinzipien & Designrichtlinien',
      'Form Briefing Form': 'Briefing-Formular',
      'New Project Process Briefing': 'Neues Projektprozess-Briefing',
      'Brefing & New Project Process': 'Züchtung & neuer Projektprozess',
      'Add Content': 'Inhalt hinzufügen',
      'Edit Content': 'Inhalt bearbeiten',
      'Refer to': 'Beziehen auf',
      Summary: 'Zusammenfassung',
      Image: 'Bild',
      'New Content': 'Neuer Inhalt',
      'New Category': 'Neue Kategorie',
      Title: 'Titel',
      'Document/Link': 'Dokument/Link',
      'Sub Contents': 'Unterinhalt',
      'Principles & design guidelines': 'Prinzipien & Designrichtlinien',
      'Best practise': 'Beste Übung',
      'Design guidelines': 'Designrichtlinien',
      Stores: 'Shops',
      'Mid mall': 'Mittleres Einkaufszentrum',
      'Edit Category': 'Kategorie bearbeiten',
      'Delete User': 'Benutzer löschen',
      'Are you sure you want to delete this user?':
        'Möchten Sie diesen Benutzer wirklich löschen?',
      'Yes, Delete': 'Ja, löschen',
      'Go Back': 'Geh zurück',
      'Select Status': 'Status auswählen',
      'Select User Type': 'Benutzertyp auswählen',
      'Select Region': 'Region wählen',
      'Select Language': 'Sprache auswählen',
      'Select category': 'Kategorie wählen',
      'Delete Category': 'Kategorie löschen',
      'Are you sure you want to delete this category?':
        'Möchten Sie diese Kategorie wirklich löschen?',
      Document: 'Dokumentieren',
      'Video URL': 'Video-URL',
      'External Link': 'Externer Link',
      Add: 'Hinzufügen',
      "Let's get started": 'Lass uns anfangen',
      'Please enter your email or username':
        'Bitte geben Sie Ihre E-Mail-Adresse oder Ihren Benutzernamen ein',
      'Email or Username': 'E-Mail Adresse oder Benutzername',
      'Forgotten your email, username or password?':
        'E-Mail, Benutzername oder Passwort vergessen?',
      'Request a password reset link':
        'Link zum Zurücksetzen des Passworts anfordern',
      Continue: 'Weitermachen',
      'No elements in this Portfolio': 'Keine Elemente in diesem Portfolio',
      'This section is under construction':
        'Dieser Abschnitt befindet sich im Aufbau. Kommen Sie bald wieder, um mehr zu erfahren...',
      'Fixtures & Furniture': 'Leuchten und Möbel',
      Accessories: 'Zubehör',
      Booth: 'Stand',
      'Mid Floor': 'Mittlere Etage',
      Walls: 'Wände',
      Windows: 'Fenster',
      Documents: 'Dokumente',
      'Scroll to bottom for documents': 'Scrollen Sie nach unten für Dokumente',
      'Unit Dimensions': 'Geräteabmessungen',
      Info: 'Informationen',
      Materials: 'Materialien',
      'End of Life': 'Lebensende',
      'Admin Area': 'Verwaltungsbereich'
    }
  },
  IT: {
    translation: {
      'Add current page': 'Aggiungi la pagina corrente',
      Help: 'Aiuto',
      'Contact Us': 'Contattaci',
      'Sky Website': 'Sito web di Sky',
      'Sky Group': 'Gruppo Sky',
      'Quick Links': 'Link veloci',
      News: 'Notizia',
      news: 'Notizia',
      Archive: 'Archivio',
      Briefing: 'Riunione',
      Projects: 'Progetti',
      Contacts: 'Contatti',
      'Add a page link': 'Aggiungi un collegamento alla pagina',
      Shop: 'Negozio',
      'Mid Mall': 'Centro commerciale medio',
      'My Account': 'Il mio conto',
      'Help & Support': 'Supporto',
      Settings: 'Impostazioni',
      'Welcome to the': 'Benvenuto nello',
      Welcome: 'Benvenuto',
      'Please Login': 'Accedere prego',
      Email: 'E-mail',
      Password: "Parola d'ordine",
      Submit: 'Invia',
      'Password Reset': 'Reimpostazione della password',
      'Request Access': 'Richiesta di accesso',
      'Sign Up': 'Iscrizione',
      Login: 'Login',
      Company: 'Azienda',
      Contact: 'Contatto',
      Category: 'Categoria',
      'Tel.': 'Tel.',
      'Web.': 'Ragnatela.',
      UK: 'UK',
      Germany: 'Germania',
      Austria: 'Austria',
      Italia: 'Italia',
      'Project Name': 'Nome del progetto',
      'Date Created': 'Data di creazione',
      'Store Plans': 'Piani negozio',
      'Rendered Visuals': 'Immagini renderizzate',
      'Meeting Minutes': 'Verbale della riunione',
      'Project Status': 'Stato del progetto',
      'Project Owner': 'Proprietario del progetto',
      'Back to Login': 'Torna al login',
      Portfolio: 'Portafoglio',
      'Show More': 'Mostra di più',
      'Show Less': 'Mostra meno',
      'Briefing Form': 'Modulo informativo',
      'System Administration': 'Amministrazione di sistema',
      Edit: 'Modificare',
      Delete: 'Eliminare',
      View: 'Visualizzazione',
      Name: 'Nome',
      Region: 'Regione',
      Status: 'Stato',
      Action: 'Azione',
      Users: 'Utenti',
      Categories: 'Categorie',
      Content: 'Contenuto',
      Reports: 'Rapporti',
      'Log in': 'Login',
      'Incorrect Email or Password': 'Email o password errati',
      'Store Plan': 'Piano negozio',
      'Territory Delivery': 'Consegna del territorio',
      'Sign out': 'Disconnessione',
      'My projects': 'I miei progetti',
      Messages: 'Messaggi',
      Details: 'Dettagli',
      Close: 'Vicino',
      Error: 'Errore',
      'First Name': 'Nome di battesimo',
      'Last Name': 'Cognome',
      Mobile: 'Mobile',
      Message: 'Messaggio',
      'User Type': 'Tipologia di utente',
      Shops: 'Negozi',
      Processes: 'Processi',
      'Design Guidelines': 'Linee guida di progettazione',
      'Best Practise': 'Miglior pratica',
      'Live Stores': 'Negozi dal vivo',
      ROI: 'ROI',
      Cardiff: 'Cardiff',
      Edinburgh: 'Edimburgo',
      Liverpool: 'Liverpool',
      Birmingham: 'Birmingham',
      'White City': 'Città Bianca',
      Battersea: 'Battersea',
      Home: 'Casa',
      Back: 'Indietro',
      'Reset password': 'Resetta la password',
      Token: 'Gettone',
      Admin: 'Amministratore',
      '3rd Party & Tactical': 'Terze parti e tattiche',
      'Merch Guidelines': 'Linee guida per il merchandising',
      'Store Furniture Information': 'Informazioni sui mobili del negozio',
      'Project History': 'Storia del progetto',
      'New User': 'Nuovo utente',
      Save: 'Salva',
      Language: 'Lingua',
      Type: 'Tipo',
      'Add User': 'Aggiungi utente',
      'Edit User': 'Modifica utente',
      'Add Category': 'Aggiungi categoria',
      Furniture: 'Mobilia',
      'Principles & Design Guidelines':
        'Principi e linee guida di progettazione',
      'Form Briefing Form': 'Modulo Briefing Form',
      'New Project Process Briefing':
        'Briefing sul processo del nuovo progetto',
      'Brefing & New Project Process': 'Brefing e nuovo processo di progetto',
      'Add Content': 'Aggiungi contenuto',
      'Edit Content': 'Modifica contenuto',
      'Refer to': 'Fare riferimento a',
      Summary: 'Riepilogo',
      Image: 'Immagine',
      'New Content': 'Nuovo contenuto',
      'New Category': 'Nuova categoria',
      Title: 'Titolo',
      'Document/Link': 'Documento/Link',
      'Sub Contents': 'Contenuti secondari',
      'Principles & design guidelines':
        'Principi e linee guida di progettazione',
      'Best practise': 'Miglior pratica',
      'Design guidelines': 'Linee guida di progettazione',
      Stores: 'I negozi',
      'Mid mall': 'Centro commerciale medio',
      'Edit Category': 'Modifica categoria',
      'Delete User': 'Elimina utente',
      'Are you sure you want to delete this user?':
        'Sei sicuro di voler eliminare questo utente?',
      'Yes, Delete': 'Sì, elimina',
      'Go Back': 'Torna indietro',
      'Select Status': 'Seleziona stato',
      'Select User Type': 'Seleziona il tipo di utente',
      'Select Region': 'Seleziona regione',
      'Select Language': 'Seleziona la lingua',
      'Select category': 'Seleziona categoria',
      'Delete Category': 'Elimina categoria',
      'Are you sure you want to delete this category?':
        'Sei sicuro di voler eliminare questa categoria?',
      Document: 'Documento',
      'Video URL': 'URL video',
      'External Link': 'Link esterno',
      Add: 'Aggiungere',
      "Let's get started": 'Iniziamo',
      'Please enter your email or username':
        'Inserisci la tua email o nome utente',
      'Email or Username': 'Email o nome utente',
      'Forgotten your email, username or password?':
        'Hai dimenticato la tua email, nome utente o password?',
      'Request a password reset link':
        'Richiedi un link per la reimpostazione della password',
      Continue: 'Continua',
      'No elements in this Portfolio': 'Nessun elemento in questo portafoglio',
      'This section is under construction':
        'Questa sezione è in costruzione, torna presto per scoprirne di più...',
      'Fixtures & Furniture': 'Arredi e Mobili',
      Accessories: 'Accessori',
      Booth: 'Cabina',
      'Mid Floor': 'Piano intermedio',
      Walls: 'Muri',
      Windows: 'Finestre',
      Documents: 'Documenti',
      'Scroll to bottom for documents': 'Scorri verso il basso per i documenti',
      'Unit Dimensions': "Dimensioni dell'unità",
      Info: 'Informazioni',
      Materials: 'Materiali',
      'End of Life': 'Fine della vita',
      'Admin Area': 'Area amministrativa'
    }
  },
  IE: {
    translation: {
      Help: 'Cabhrú',
      'Contact Us': 'Glaoigh orainn',
      'Sky Website': 'Suíomh Gréasáin Sky',
      'Sky Group': 'Sky Group',
      'Quick Links': 'Naisc Thapa',
      News: 'Nuacht',
      Archive: 'Cartlann',
      Briefing: 'Seisiún faisnéise',
      Projects: 'Tionscadail',
      Contacts: 'Teagmhálaithe',
      'Add a page link': 'Cuir nasc leathanach leis',
      Shop: 'Siopa',
      'Mid Mall': 'Meall Láir',
      'My Account': 'Mo chuntas',
      'Help & Support': 'Cabhair & Tacaíocht',
      Settings: 'Socruithe',
      'Welcome to the': 'Fáilte chuig an',
      Welcome: 'Fáilte',
      'Please Login': 'Logáil Isteach',
      Email: 'Ríomhphost',
      Password: 'Pasfhocal',
      Submit: 'Cuir',
      'Password Reset': 'Athshocrú Pasfhocal',
      'Request Access': 'Iarr Rochtain',
      'Sign Up': 'Clarú',
      Login: 'Logáil isteach',
      Company: 'Cuideachta',
      Contact: 'Teagmháil',
      Category: 'Catagóir',
      'Tel.': 'Teil.',
      'Web.': 'Gréasán.',
      UK: 'RA',
      Germany: 'an Ghearmáin',
      Austria: 'an Ostair',
      Italia: 'an Iodáil',
      'Project Name': 'Ainm an Tionscadail',
      'Date Created': 'Dáta Cruthaithe',
      'Store Plans': 'Pleananna Stórais',
      'Rendered Visuals': 'Amharcanna Rindreáilte',
      'Meeting Minutes': 'Miontuairiscí cruinnithe',
      'Project Status': 'Stádas an Tionscadail',
      'Project Owner': 'Úinéir an Tionscadail',
      'Back to Login': 'Ar ais go Logáil Isteach',
      Portfolio: 'Punann',
      'Show More': 'Taispeáin Tuilleadh',
      'Show Less': 'Taispeáin Níos lú',
      'Briefing Form': 'Foirm Faisnéise',
      'System Administration': 'Riarachán Córais',
      Edit: 'Cuir',
      Delete: 'in eagar Scrios',
      View: 'Amharc',
      Name: 'Ainm',
      Region: 'Réigiún',
      Status: 'Stádas',
      Action: 'Gníomh',
      Users: 'Úsáideoirí',
      Categories: 'Catagóirí',
      Content: 'Ábhar',
      Reports: 'Tuarascálacha',
      'Log in': 'Logáil isteach',
      'Incorrect Email or Password': 'Ríomhphost nó Pasfhocal mícheart',
      'Store Plan': 'Plean Stórais',
      'Territory Delivery': 'Seachadadh Críoch',
      'Sign out': 'Logáil Amach',
      'My projects': 'Mo thionscadail',
      Messages: 'Teachtaireachtaí',
      Details: 'Sonraí',
      Close: 'Dún',
      Error: 'Earráid',
      'First Name': 'Ainm',
      'Last Name': 'Sloinne',
      Mobile: 'Soghluaiste',
      Message: 'Teachtaireacht',
      'User Type': 'Cineál Úsáideora',
      Shops: 'Siopaí',
      Processes: 'Próisis',
      'Design Guidelines': 'Treoirlínte Dearaidh',
      'Best Practise': 'Cleachtais is Fearr',
      'Live Stores': 'Siopaí Beo',
      ROI: 'Roi',
      Cardiff: 'Caerdydd',
      Edinburgh: 'Dún Éideann',
      Liverpool: 'Learpholl',
      Birmingham: 'Birmingham',
      'White City': 'Cathair Bhán',
      Battersea: 'Battersea',
      Home: 'Baile',
      Back: 'ar ais',
      'Reset password': 'Athshocraigh pasfhocal',
      Token: 'Comhartha',
      Admin: 'Riarachán',
      '3rd Party & Tactical': '3ú Páirtí & Oirbheartaíochta',
      'Merch Guidelines': 'Treoirlínte na gCeannaithe',
      'Store Furniture Information': 'Stóráil Faisnéise Troscán',
      'Project History': 'Stair an Tionscadail',
      'New User': 'Úsáideoir Nua',
      Save: 'Sábháil',
      Language: 'Teanga',
      Type: 'Cineál',
      'Add User': 'Cuir Úsáideoir leis',
      'Edit User': 'Cuir Úsáideoir in Eagar',
      'Add Category': 'Cuir Catagóir leis',
      Furniture: 'Troscán',
      'Principles & Design Guidelines': 'Prionsabail agus Treoirlínte Dearaidh',
      'Form Briefing Form': 'Foirm Faisnéise',
      'New Project Process Briefing': 'Faisnéisiú Próisis Tionscadail Nua',
      'Brefing & New Project Process':
        'Próiseas Grúdaireachta & Tionscadail Nua',
      'Add Content': 'Cuir Ábhar leis',
      'Edit Content': 'Cuir Ábhar in Eagar',
      'Refer to': 'Déan tagairt do',
      Summary: 'Achoimre',
      Image: 'Íomha',
      'New Content': 'Ábhar Nua',
      'New Category': 'Catagóir Nua',
      Title: 'Teideal',
      'Document/Link': 'Doiciméad/Nasc',
      'Sub Contents': 'Fo-Ábhar',
      'Principles & design guidelines': 'Prionsabail agus treoirlínte dearaidh',
      'Best practise': 'Dea-chleachtas',
      'Design guidelines': 'Treoirlínte deartha',
      Stores: 'Stórais',
      'Mid mall': 'Lárionad',
      'Edit Category': 'Cuir an Chatagóir in Eagar',
      'Delete User': 'Scrios Úsáideoir',
      'Are you sure you want to delete this user?':
        'An bhfuil tú cinnte gur mhaith leat an t-úsáideoir seo a scriosadh?',
      'Yes, Delete': 'Sea, Scrios',
      'Go Back': 'Dul ar ais',
      'Select Status': 'Roghnaigh Stádas',
      'Select User Type': 'Roghnaigh Cineál Úsáideora',
      'Select Region': 'Roghnaigh Réigiún',
      'Select Language': 'Roghnaigh Teanga',
      'Select category': 'Roghnaigh catagóir',
      'Delete Category': 'Scrios Catagóir',
      'Are you sure you want to delete this category?':
        'An bhfuil tú cinnte gur mhaith leat an chatagóir seo a scriosadh?',
      Document: 'Doiciméad',
      'Video URL': 'URL an fhíseáin',
      'External Link': 'Nasc Seachtrach',
      Add: 'Cuir',
      "Let's get started": 'Ar aghaidh linn',
      'Please enter your email or username':
        'Cuir isteach do ríomhphost nó ainm úsáideora',
      'Email or Username': 'Ríomhphost nó Ainm Úsáideora',
      'Forgotten your email, username or password?':
        'An ndearna tú dearmad ar do ríomhphost, ainm úsáideora nó pasfhocal?',
      Continue: 'Leanúint ar aghaidh'
    }
  },
  FR: {
    translation: {
      Help: 'Aider',
      'Contact Us': 'Contactez-nous',
      'Sky Website': 'Site Web du ciel',
      'Sky Group': 'Groupe Ciel',
      'Quick Links': 'Liens rapides',
      News: 'Nouvelles',
      Archive: 'Archive',
      Briefing: 'Compte rendu',
      Projects: 'Projets',
      Contacts: 'Contacts',
      'Add a page link': 'Ajouter un lien de page',
      Shop: 'Boutique',
      'Mid Mall': 'Mi centre commercial',
      'My Account': 'Mon compte',
      'Help & Support': "Support d'aide",
      Settings: 'Paramètres',
      'Welcome to the': 'Bienvenue sur le site de la',
      Welcome: 'Accueillir',
      'Please Login': 'Veuillez vous connecter',
      Email: 'E-mail',
      Password: 'Mot de passe',
      Submit: 'Soumettre',
      'Password Reset': 'Réinitialisation du mot de passe',
      'Request Access': "Demande d'accès",
      'Sign Up': "S'inscrire",
      Login: 'Connexion',
      Company: 'Entreprise',
      Contact: 'Contact',
      Category: 'Catégorie',
      'Tel.': 'Tél.',
      'Web.': 'La toile.',
      UK: 'ROYAUME-UNI',
      Germany: 'Allemagne',
      Austria: "L'Autriche",
      Italia: 'Italie',
      'Project Name': 'Nom du projet',
      'Date Created': 'Date créée',
      'Store Plans': 'Forfaits de magasin',
      'Rendered Visuals': 'Visuels rendus',
      'Meeting Minutes': 'Comptes rendus des réunions',
      'Project Status': "L'état du projet",
      'Project Owner': 'Propriétaire du projet',
      'Back to Login': 'Retour connexion',
      Portfolio: 'Portefeuille',
      'Show More': 'Montre plus',
      'Show Less': 'Montrer moins',
      'Briefing Form': "Formulaire d'information",
      'System Administration': "L'administration du système",
      Edit: 'Modifier',
      Delete: 'Supprimer',
      View: 'Voir',
      Name: 'Nom',
      Region: 'Région',
      Status: 'Statut',
      Action: 'Action',
      Users: 'Utilisateurs',
      Categories: 'Catégories',
      Content: 'Contenu',
      Reports: 'Rapports',
      'Log in': 'Connexion',
      'Incorrect Email or Password': 'Email ou mot de passe incorrect',
      'Store Plan': 'Plan du magasin',
      'Territory Delivery': 'Livraison par territoire',
      'Sign out': 'Se déconnecter',
      'My projects': 'Mes projets',
      Messages: 'Messages',
      Details: 'Détails',
      Close: 'Fermer',
      Error: 'Erreur',
      'First Name': 'Prénom',
      'Last Name': 'Nom de famille',
      Mobile: 'Mobile',
      Message: 'Message',
      'User Type': "Type d'utilisateur",
      Shops: 'Magasins',
      Processes: 'Processus',
      'Design Guidelines': 'Directives de conception',
      'Best Practise': 'Meilleure pratique',
      'Live Stores': 'Boutiques en direct',
      ROI: 'ROI',
      Cardiff: 'Cardiff',
      Edinburgh: 'Edinbourg',
      Liverpool: 'Liverpool',
      Birmingham: 'Birmingham',
      'White City': 'Ville Blanche',
      Battersea: 'Battersea',
      Home: 'Maison',
      Back: 'Dos',
      'Reset password': 'Réinitialiser le mot de passe',
      Token: 'Jeton',
      Admin: 'Administrateur',
      '3rd Party & Tactical': 'Tierce partie et tactique',
      'Merch Guidelines': 'Consignes de merchandising',
      'Store Furniture Information': 'Informations sur les meubles du magasin',
      'Project History': 'Historique du projet',
      'New User': 'Nouvel utilisateur',
      Save: 'Sauvegarder',
      Language: 'Langue',
      Type: 'Taper',
      'Add User': 'Ajouter un utilisateur',
      'Edit User': "Modifier l'utilisateur",
      'Add Category': 'Ajouter une catégorie',
      Furniture: 'Meubles',
      'Principles & Design Guidelines': 'Principes et directives de conception',
      'Form Briefing Form': 'Formulaire Formulaire de briefing',
      'New Project Process Briefing':
        "Nouvelle séance d'information sur le processus de projet",
      'Brefing & New Project Process': 'Résumé et nouveau processus de projet',
      'Add Content': 'Ajouter du contenu',
      'Edit Content': 'Modifier le contenu',
      'Refer to': 'Faire référence à',
      Summary: 'Résumé',
      Image: 'Image',
      'New Content': 'Nouveau contenu',
      'New Category': 'Nouvelle catégorie',
      Title: 'Titre',
      'Document/Link': 'Document/Lien',
      'Sub Contents': 'Sous-contenu',
      'Principles & design guidelines': 'Principes et directives de conception',
      'Best practise': 'Meilleure pratique',
      'Design guidelines': 'Directives de conception',
      Stores: 'Magasins',
      'Mid mall': 'Centre commercial moyen',
      'Edit Category': 'Modifier la catégorie',
      'Delete User': "Supprimer l'utilisateur",
      'Are you sure you want to delete this user?':
        'Voulez-vous vraiment supprimer cet utilisateur ?',
      'Yes, Delete': 'Oui, supprimer',
      'Go Back': 'Retourner',
      'Select Status': 'Sélectionnez le statut',
      'Select User Type': "Sélectionnez le type d'utilisateur",
      'Select Region': 'Choisissez une région',
      'Select Language': 'Choisir la langue',
      'Select category': 'Choisir une catégorie',
      'Delete Category': 'Supprimer la catégorie',
      'Are you sure you want to delete this category?':
        'Voulez-vous vraiment supprimer cette catégorie?',
      Document: 'Document',
      'Video URL': 'URL de la vidéo',
      'External Link': 'Lien externe',
      Add: 'Ajouter',
      "Let's get started": 'Commençons',
      'Please enter your email or username':
        "Veuillez saisir votre email ou votre nom d'utilisateur",
      'Email or Username': "E-mail ou nom d'utilisateur",
      'Forgotten your email, username or password?':
        "Vous avez oublié votre email, nom d'utilisateur ou mot de passe ?",
      Continue: 'Continuer'
    }
  },
  NL: {
    translation: {
      Help: 'Hulp',
      'Contact Us': 'Neem contact met ons op',
      'Sky Website': 'Sky-website',
      'Sky Group': 'Luchtgroep',
      'Quick Links': 'Snelle links',
      News: 'Nieuws',
      Archive: 'Archief',
      Briefing: 'Briefing',
      Projects: 'Projecten',
      Contacts: 'Contacten',
      'Add a page link': 'Voeg een paginalink toe',
      Shop: 'Winkel',
      'Mid Mall': 'midden winkelcentrum',
      'My Account': 'Mijn rekening',
      'Help & Support': 'Help & Ondersteuning',
      Settings: 'Instellingen',
      'Welcome to the': 'Welkom bij de',
      Welcome: 'Welkom',
      'Please Login': 'Log alsjeblieft in',
      Email: 'E-mail',
      Password: 'Wachtwoord',
      Submit: 'Indienen',
      'Password Reset': 'Wachtwoord reset',
      'Request Access': 'Toegang vragen',
      'Sign Up': 'Aanmelden',
      Login: 'Log in',
      Company: 'Bedrijf',
      Contact: 'Contact',
      Category: 'Categorie',
      'Tel.': 'Tel.',
      'Web.': 'Web.',
      UK: 'VK',
      Germany: 'Duitsland',
      Austria: 'Oostenrijk',
      Italia: 'Italië',
      'Project Name': 'Naam van het project',
      'Date Created': 'Datum gecreeërd',
      'Store Plans': 'Winkelplannen',
      'Rendered Visuals': 'gerenderde beelden',
      'Meeting Minutes': 'Vergadernotulen',
      'Project Status': 'Project status',
      'Project Owner': 'Projecteigenaar',
      'Back to Login': 'Terug naar Inloggen',
      Portfolio: 'Portefeuille',
      'Show More': 'Laat meer zien',
      'Show Less': 'Laat minder zien',
      'Briefing Form': 'Briefingformulier',
      'System Administration': 'Systeem administratie',
      Edit: 'Bewerking',
      Delete: 'Verwijderen',
      View: 'Weergave',
      Name: 'Naam',
      Region: 'Regio',
      Status: 'Toestand',
      Action: 'Actie',
      Users: 'gebruikers',
      Categories: 'Categorieën',
      Content: 'Inhoud',
      Reports: 'rapporten',
      'Log in': 'Log in',
      'Incorrect Email or Password': 'Incorrecte email of wachtwoord',
      'Store Plan': 'Winkelplan',
      'Territory Delivery': 'Levering territorium',
      'Sign out': 'Afmelden',
      'My projects': 'Mijn projecten',
      Messages: 'Berichten',
      Details: 'Details',
      Close: 'Dichtbij',
      Error: 'Fout',
      'First Name': 'Voornaam',
      'Last Name': 'Achternaam',
      Mobile: 'Mobiel',
      Message: 'Bericht',
      'User Type': 'Gebruikerstype',
      Shops: 'Winkels',
      Processes: 'Processen',
      'Design Guidelines': 'Ontwerp richtlijnen',
      'Best Practise': 'Beste oefening',
      'Live Stores': 'Live-winkels',
      ROI: 'ROI',
      Cardiff: 'Cardiff',
      Edinburgh: 'Edinburgh',
      Liverpool: 'Liverpool',
      Birmingham: 'Birmingham',
      'White City': 'witte stad',
      Battersea: 'Batterzee',
      Home: 'Thuis',
      Back: 'Rug',
      'Reset password': 'Wachtwoord resetten',
      Token: 'Token',
      Admin: 'Beheerder',
      '3rd Party & Tactical': '3e partij & tactisch',
      'Merch Guidelines': 'Richtlijnen voor koopwaar',
      'Store Furniture Information': 'Meubelinformatie opslaan',
      'Project History': 'Projectgeschiedenis',
      'New User': 'Nieuwe gebruiker',
      Save: 'Redden',
      Language: 'Taal',
      Type: 'Type',
      'Add User': 'Voeg gebruiker toe',
      'Edit User': 'Bewerk gebruiker',
      'Add Category': 'Categorie toevoegen',
      Furniture: 'Meubilair',
      'Principles & Design Guidelines': 'Principes en ontwerprichtlijnen',
      'Form Briefing Form': 'Formulier Briefingformulier',
      'New Project Process Briefing': 'Nieuwe projectprocesbriefing',
      'Brefing & New Project Process': 'Brefing & nieuw projectproces',
      'Add Content': 'Content toevoegen',
      'Edit Content': 'Inhoud bewerken',
      'Refer to': 'Verwijzen naar',
      Summary: 'Samenvatting',
      Image: 'Afbeelding',
      'New Content': 'Nieuwe inhoud',
      'New Category': 'Nieuwe categorie',
      Title: 'Titel',
      'Document/Link': 'Document/Link',
      'Sub Contents': 'Subinhoud',
      'Principles & design guidelines': 'Principes & ontwerprichtlijnen',
      'Best practise': 'Beste oefening',
      'Design guidelines': 'Ontwerp richtlijnen',
      Stores: 'Winkels',
      'Mid mall': 'Midden winkelcentrum',
      'Edit Category': 'Categorie bewerken',
      'Delete User': 'Verwijder gebruiker',
      'Are you sure you want to delete this user?':
        'Weet je zeker dat je deze gebruiker wilt verwijderen?',
      'Yes, Delete': 'Ja, verwijderen',
      'Go Back': 'Ga terug',
      'Select Status': 'Selecteer Status',
      'Select User Type': 'Selecteer gebruikerstype',
      'Select Region': 'Regio selecteren',
      'Select Language': 'Selecteer taal',
      'Select category': 'Selecteer categorie',
      'Delete Category': 'Categorie verwijderen',
      'Are you sure you want to delete this category?':
        'Weet je zeker dat je deze categorie wilt verwijderen?',
      Document: 'Document',
      'Video URL': 'Video URL',
      'External Link': 'Externe link',
      Add: 'Toevoegen',
      "Let's get started": 'Laten we beginnen',
      'Please enter your email or username':
        'Voer uw e-mailadres of gebruikersnaam in',
      'Email or Username': 'E-mail of gebruikersnaam',
      'Forgotten your email, username or password?':
        'E-mail, gebruikersnaam of wachtwoord vergeten??',
      Continue: 'Doorgaan'
    }
  }
};
