import Flex from 'components/common/Flex';
import React, { Fragment, useMemo, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import ActiveUsers from './ActiveUsers';
import { useTranslation } from 'react-i18next';

const Users = ({ users, updateSuccess }) => {
  const { t } = useTranslation();
  const [kind, setKind] = useState('all-users');
  const kinds = [
    {
      key: 'all-users',
      name: 'All Users'
    },
    {
      key: 'on-hold-users',
      name: 'On Hold Users'
    }
  ];

  const activeUsers = useMemo(
    () => users.filter(user => user.on_holdYN === '0'),
    [users]
  );
  const onHoldUsers = useMemo(
    () => users.filter(user => user.on_holdYN === '1'),
    [users]
  );

  return (
    <Tab.Container
      activeKey={kind}
      onSelect={k => setKind(k)}
      id="countries-tab"
      transition={false}
    >
      <Flex justifyContent="between" alignItems="center" className="">
        <Nav variant="tabs" className="flex-row mb-3">
          {kinds.map((c, index) => (
            <Fragment key={index}>
              <Nav.Item>
                <Nav.Link eventKey={c.key} className="fw-semi-bold fs-1">
                  {t(c.name)}
                </Nav.Link>
              </Nav.Item>
            </Fragment>
          ))}
        </Nav>
      </Flex>
      <Tab.Content>
        {kinds.map((c, index) => (
          <Fragment key={index}>
            <Tab.Pane eventKey={c.key} title={c.name} className="p-3">
              <ActiveUsers
                updateSuccess={updateSuccess}
                users={kind === 'all-users' ? activeUsers : onHoldUsers}
              />
            </Tab.Pane>
          </Fragment>
        ))}
      </Tab.Content>
    </Tab.Container>
  );
};

export default Users;
