import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import https from 'utils/https';
import { ENDPOINTS } from 'utils/endpoints/endpoints';
import { resetContentData, setContentData } from 'redux/slicers/contentSlice';

async function getContent(id) {
  const { data } = await https.get(
    `${ENDPOINTS.GET_CONTENT}?userISbb_usersID=${id}`
  );

  return data;
}

export const useGetContentMutation = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: id => getContent(id),
    onSuccess: async data => {
      await dispatch(resetContentData());
      // const updatedData = data.list.map(el => {
      //   if (el.content01ISbb_q4sg_contentID) {
      //     const gategoryPath = `Portfolio/${el.category01ISbb_q4sg_content_categoriesID_display}/`;
      //   }
      // });
      await dispatch(setContentData(data.list));
    },
    onError: error => {
      console.log(error);
    }
  });
};
