import FalconCloseButton from 'components/common/FalconCloseButton';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FurtitureFixtures from './FurnitureFixtures';
import MainCategory from './MainCategory';
import { useSelector } from 'react-redux';

const AddContentCategoryModal = ({
  show,
  handleClose,
  openContentForm,
  formData,
  setFormData,
  setContentModal
}) => {
  const [category, setCategory] = useState('');
  const [path, setPath] = useState('');
  const { user } = useSelector(state => state.auth);

  const { t } = useTranslation();

  const title = t('New Content');

  const handleChange = (e, type) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    if (type) setContentModal(type);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    handleClose();
    openContentForm();
  };

  const handleTeamChange = e => {
    e.preventDefault();
    setCategory(e.target.value);
    setContentModal(e.target.value);
    setFormData({ ...formData, selected_pathISplaintextbox: e.target.value });
  };

  useEffect(() => {
    if (formData.category01ISbb_q4sg_content_categoriesID !== '131156') {
      setFormData(prev => ({
        ...prev,
        category03ISbb_q4sg_content_categoriesID: ''
      }));
    }
  }, [formData.category01ISbb_q4sg_content_categoriesID]);

  useEffect(() => {
    return () => {
      setFormData({
        category01ISbb_q4sg_content_categoriesID: '',
        category03ISbb_q4sg_content_categoriesID: ''
      });
      setCategory('');
    };
  }, []);

  useEffect(() => {
    let p;
    if (category !== 'f&f') {
      const arrayToLetters = category.split('');
      const firstLetter = arrayToLetters[0]?.toUpperCase();
      const rest = arrayToLetters?.slice(1, arrayToLetters.length).join('');
      p = `${firstLetter}${rest}`;
      setFormData({
        ...formData,
        selected_pathISplaintextbox: p
      });
    } else {
      p = category.toUpperCase();
      setFormData({
        ...formData,
        selected_pathISplaintextbox: `${p}${path}`
      });
    }
  }, [category, path]);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      contentClassName="border-0"
      centered
      size="lg"
    >
      <Modal.Header className="bg-modal-header light border-bottom-0">
        <FalconCloseButton noOutline onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <fieldset className={'form-legend-wrapper pb-6 px-4'}>
          <p className="text-center">
            Please select below where you would like to add new content
          </p>
          <legend className="item-title fs-1 fw-semi-bold">{t(title)}</legend>
          <Form onSubmit={handleSubmit} className="px-6 pt-4">
            {user.user_type === 'Supplier' && (
              <FurtitureFixtures
                form={formData}
                action={e => handleChange(e, 'f&f')}
                setPath={setPath}
              />
            )}
            {(user.user_type === 'Team' || user.user_type === 'Admin') && (
              <MainCategory category={category} action={handleTeamChange} />
            )}
            {category === 'f&f' && (
              <FurtitureFixtures
                form={formData}
                action={e => handleChange(e, 'f&f')}
                setPath={setPath}
              />
            )}

            <div className="text-end">
              <Button variant="primary" type="submit">
                Load Content Form
              </Button>
            </div>
          </Form>
        </fieldset>
      </Modal.Body>
    </Modal>
  );
};

export default AddContentCategoryModal;
